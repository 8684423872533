import React from 'react';
import { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import Icon1 from '../../images/process-1-pickup.jpg';
import Icon2 from '../../images/process-2-sorting.jpg';
import Icon3 from '../../images/process-3-washing.jpg';
import Icon4 from '../../images/process-4-drying.jpg';
import Icon5 from '../../images/process-5-folding.jpg';
import Icon6 from '../../images/process-6-quality.jpg';
import Icon7 from '../../images/process-7-delivery.jpg';
import './process.css';

const Process = () => {
    const { t } = useTranslation();
    const elementsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(entry.target.dataset.index % 2 == 0 ? "animate-right" : "animate-left");
                        observer.unobserve(entry.target); // Stop observing once animated
                    }
                });
            },
            { threshold: 0.5 } // Triggers when 50% of the element is visible
        );

        elementsRef.current.forEach((el) => {
            if (el) observer.observe(el);
        });

        return () => observer.disconnect(); // Cleanup observer on unmount
    }, []);


    return (
        <div id='process' className='process-container'>
            <h1 className='process-title'>{t('app.process')}</h1>
            <div className="process-steps">
                <div className="process-center-line" />
                <div className="process-step process-step-left">
                    <section className='process-step-section' key={0} ref={(el) => (elementsRef.current[0] = el)} data-index={0}>
                        <img className='process-step-icon process-step-icon-left' src={Icon1} alt='' />
                        <span className="process-step-title">{t('app.process1Tittle')}</span>
                        <p className="process-step-content">{t('app.process1Content')}</p>
                    </section>
                </div>
                <div className="process-step process-step-right">
                    <section className='process-step-section' key={1} ref={(el) => (elementsRef.current[1] = el)} data-index={1}>
                        <img className='process-step-icon process-step-icon-right' src={Icon2} alt='' />
                        <span className="process-step-title">{t('app.process2Tittle')}</span>
                        <p className="process-step-content">{t('app.process2Content')}</p>
                    </section>
                </div>
                <div className="process-step process-step-left">
                    <section className='process-step-section' key={2} ref={(el) => (elementsRef.current[2] = el)} data-index={2}>
                        <img className='process-step-icon process-step-icon-left' src={Icon3} alt='' />
                        <span className="process-step-title">{t('app.process3Tittle')}</span>
                        <p className="process-step-content">{t('app.process3Content')}</p>
                    </section>
                </div>
                <div className="process-step process-step-right">
                    <section className='process-step-section' key={3} ref={(el) => (elementsRef.current[3] = el)} data-index={3}>
                        <img className='process-step-icon process-step-icon-right' src={Icon4} alt='' />
                        <span className="process-step-title">{t('app.process4Tittle')}</span>
                        <p className="process-step-content">{t('app.process4Content')}</p>
                    </section>
                </div>
                <div className="process-step process-step-left">
                    <section className='process-step-section' key={4} ref={(el) => (elementsRef.current[4] = el)} data-index={4}>
                        <img className='process-step-icon process-step-icon-left' src={Icon5} alt='' />
                        <span className="process-step-title">{t('app.process5Tittle')}</span>
                        <p className="process-step-content">{t('app.process5Content')}</p>
                    </section>
                </div>
                <div className="process-step process-step-right">
                    <section className='process-step-section' key={5} ref={(el) => (elementsRef.current[5] = el)} data-index={5}>
                        <img className='process-step-icon process-step-icon-right' src={Icon6} alt='' />
                        <span className="process-step-title">{t('app.process6Tittle')}</span>
                        <p className="process-step-content">{t('app.process6Content')}</p>
                    </section>
                </div>
                <div className="process-step process-step-left">
                    <section className='process-step-section' key={6} ref={(el) => (elementsRef.current[6] = el)} data-index={6}>
                        <img className='process-step-icon process-step-icon-left' src={Icon7} alt='' />
                        <span className="process-step-title">{t('app.process7Tittle')}</span>
                        <p className="process-step-content">{t('app.process7Content')}</p>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Process;
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@lagoagest-lda/common-frontend-core';
import IconWashing from '../../images/service-washing.tsx';
import IconTreatment from '../../images/service-treatment.tsx';
import IconRenting from '../../images/service-renting.tsx';
import './service.css';

const Services = () => {
    const { t } = useTranslation();
    return (
        <div id="services" className='services-container'>
            <h1 className='services-title'>{t('app.services')}</h1>
            <div className='services-values'>
                <Card description='app.services1Content' header='app.services1Tittle' icon={IconWashing} iconProps={{height: 90, width: 90}} />
                <Card description='app.services2Content' header='app.services2Tittle' icon={IconTreatment} iconProps={{height: 90, width: 90}} />
                <Card description='app.services3Content' header='app.services3Tittle' icon={IconRenting} iconProps={{height: 90, width: 90}} />
            </div>
        </div>
    )
}

export default Services;
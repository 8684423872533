import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import "./EnergyDashboard.css";
import { Modal } from "react-bootstrap";
import { FaExpandArrowsAlt } from "react-icons/fa";



// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const EnergyDashboard: React.FC = () => {
  const [lineChartDate, setLineChartDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [lineChartView, setLineChartView] = useState<"Day" | "Month" | "Year">(
    "Day"
  );
  const [barChartDate, setBarChartDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [barChartView, setBarChartView] = useState<"Day" | "Month" | "Year">(
    "Day"
  );

  const [showModalLine, setShowModalLine] = useState(false); // State for modal visibility
  const [showModalBar, setShowModalBar] = useState(false); // State for modal visibility

  // States for chart titles
  const [lineChartTitle, setLineChartTitle] = useState<string>(
    "Tendência de Energia - Dia"
  );

  const [barChartTitle, setBarChartTitle] = useState<string>(
    "Tendência de Receita - Dia"
  );

  // Example Data
  const [lineData, setLineData] = useState<ChartData<"line">>({
    labels: ["00:00", "02:00", "04:00", "06:00", "08:00", "10:00", "12:00", "14:00", "16:00", "18:00", "20:00", "22:00"],
    datasets: [
      {
        label: "Saída PV",
        data: [30, 25, 50, 45, 60, 70, 85, 30, 25, 50, 45, 60],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Energia da Rede Elétrica",
        data: [10, 20, 15, 25, 20, 35, 30, 10, 20, 15, 25, 30],
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
        tension: 0.4,
      },
    ],
  });

  // Example Data
  const [lineDataDetail, setLineDataDetail] = useState<ChartData<"line">>({
    labels: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00",
      "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
    datasets: [
      {
        label: "Saída PV",
        data: [30, 25, 50, 45, 60, 70, 85, 30, 25, 50, 45, 60, 30, 50, 45, 60, 70, 85, 30, 50, 45, 60, 30, 25],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Energia da Rede Elétrica",
        data: [10, 20, 15, 25, 20, 35, 30, 10, 20, 15, 25, 30, 10, 20, 15, 25, 20, 35, 30, 10, 20, 15, 15, 25],
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
        tension: 0.4,
      },
    ],
  });

  const updateLineChartView = (view: "Day" | "Month" | "Year") => {
    setLineChartView(view);
    const viewTitleMap = { Day: "Dia", Month: "Mês", Year: "Ano" };
    setLineChartTitle(`Tendência de Energia - ${viewTitleMap[view]}`);
    if (view === "Day") {
      setLineData({
        labels: ["00:00", "02:00", "04:00", "06:00", "08:00", "10:00", "12:00", "14:00", "16:00", "18:00", "20:00", "22:00"],
        datasets: [
          {
            label: "Saída PV",
            data: [30, 25, 50, 45, 60, 70, 85, 30, 25, 50, 45, 60],
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
          },
          {
            label: "Energia da Rede Elétrica",
            data: [10, 20, 15, 25, 20, 35, 30, 10, 20, 15, 25, 30],
            borderColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
          },
        ],
      });
    } else if (view === "Month") {
      setLineData({
        labels: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        datasets: [
          {
            label: "Saída PV",
            data: [300, 250, 400, 350, 500, 600, 700, 300, 250, 400, 350, 500],
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
          },
          {
            label: "Energia da Rede Elétrica",
            data: [100, 200, 150, 250, 200, 350, 300, 100, 200, 150, 250, 300],
            borderColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
          },
        ],
      });
    } else if (view === "Year") {
      setLineData({
        labels: ["2020", "2021", "2022", "2023", "2024"],
        datasets: [
          {
            label: "Saída PV",
            data: [4000, 4500, 4200, 4700, 4800],
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
          },
          {
            label: "Energia da Rede Elétrica",
            data: [1500, 2000, 1800, 2200, 2100],
            borderColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
          },
        ],
      });
    }
  };

  const updateLineDetailChartView = (view: "Day" | "Month" | "Year") => {
    setLineChartView(view);
    if (view === "Day") {
      setLineDataDetail({
        labels: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00",
          "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
        datasets: [
          {
            label: "Saída PV",
            data: [30, 25, 50, 45, 60, 70, 85, 30, 25, 50, 45, 60, 30, 50, 45, 60, 70, 85, 30, 50, 45, 60, 30, 25],
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            fill: true,
            tension: 0.4,
          },
          {
            label: "Energia da Rede Elétrica",
            data: [10, 20, 15, 25, 20, 35, 30, 10, 20, 15, 25, 30, 10, 20, 15, 25, 20, 35, 30, 10, 20, 15, 15, 25],
            borderColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            fill: true,
            tension: 0.4,
          },
        ],
      });
    } else if (view === "Month") {
      setLineDataDetail({
        labels: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        datasets: [
          {
            label: "Saída PV",
            data: [300, 250, 400, 350, 500, 600, 700, 300, 250, 400, 350, 500],
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            tension: 0.4,
          },
          {
            label: "Energia da Rede Elétrica",
            data: [100, 200, 150, 250, 200, 350, 300, 100, 200, 150, 250, 300],
            borderColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            tension: 0.4,
          },
        ],
      });
    } else if (view === "Year") {
      setLineDataDetail({
        labels: ["2020", "2021", "2022", "2023", "2024"],
        datasets: [
          {
            label: "Saída PV",
            data: [4000, 4500, 4200, 4700, 4800],
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            tension: 0.4,
          },
          {
            label: "Energia da Rede Elétrica",
            data: [1500, 2000, 1800, 2200, 2100],
            borderColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            tension: 0.4,
          },
        ],
      });
    }
  };

  const [barData, setBarData] = useState<ChartData<"bar">>({
    labels: ["00:00", "02:00", "04:00", "06:00", "08:00", "10:00", "12:00", "14:00", "16:00", "18:00", "20:00", "22:00"],
    datasets: [
      {
        label: "Receita",
        data: [5, 10, 8, 12, 15, 20, 5, 10, 8, 12, 5, 12],
        backgroundColor: "rgba(153, 102, 255, 0.8)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  });

  const [barDataDetail, setBarDataDetail] = useState<ChartData<"bar">>({
    labels: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00",
      "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
    datasets: [
      {
        label: "Receita",
        data: [5, 10, 8, 12, 15, 20, 5, 10, 8, 12, 5, 12, 5, 10, 8, 12, 15, 20, 5, 10, 8, 12, 5, 12],
        backgroundColor: "rgba(153, 102, 255, 0.8)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  });

  const updateBarChartView = (view: "Day" | "Month" | "Year") => {
    setBarChartView(view);
    const viewTitleMap = { Day: "Dia", Month: "Mês", Year: "Ano" };
    setBarChartTitle(`Tendência de Receita - ${viewTitleMap[view]}`);
    if (view === "Day") {
      setBarData({
        labels: ["00:00", "02:00", "04:00", "06:00", "08:00", "10:00", "12:00", "14:00", "16:00", "18:00", "20:00", "22:00"],
        datasets: [
          {
            label: "Receita",
            data: [5, 10, 8, 12, 15, 20, 5, 10, 8, 12, 5, 12],
            backgroundColor: "rgba(153, 102, 255, 0.8)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          },
        ],
      });
    } else if (view === "Month") {
      setBarData({
        labels: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        datasets: [
          {
            label: "Receita",
            data: [300, 250, 400, 350, 500, 600, 700, 300, 250, 400, 350, 500],
            backgroundColor: "rgba(153, 102, 255, 0.8)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          },
        ],
      });
    } else if (view === "Year") {
      setBarData({
        labels: ["2020", "2021", "2022", "2023", "2024"],
        datasets: [
          {
            label: "Receita",
            data: [4000, 4500, 4200, 4700, 4800],
            backgroundColor: "rgba(153, 102, 255, 0.8)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          },
        ],
      });
    }
  };

  const updateBarDetailChartView = (view: "Day" | "Month" | "Year") => {
    setBarChartView(view);
    if (view === "Day") {
      setBarDataDetail({
        labels: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00",
          "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
        datasets: [
          {
            label: "Receita",
            data: [5, 10, 8, 12, 15, 20, 5, 10, 8, 12, 5, 12, 5, 10, 8, 12, 15, 20, 5, 10, 8, 12, 5, 12],
            backgroundColor: "rgba(153, 102, 255, 0.8)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          },
        ],
      });
    } else if (view === "Month") {
      setBarDataDetail({
        labels: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        datasets: [
          {
            label: "Receita",
            data: [300, 250, 400, 350, 500, 600, 700, 300, 250, 400, 350, 500],
            backgroundColor: "rgba(153, 102, 255, 0.8)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          },
        ],
      });
    } else if (view === "Year") {
      setBarDataDetail({
        labels: ["2020", "2021", "2022", "2023", "2024"],
        datasets: [
          {
            label: "Receita",
            data: [4000, 4500, 4200, 4700, 4800],
            backgroundColor: "rgba(153, 102, 255, 0.8)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          },
        ],
      });
    }
  };

  const options: ChartOptions<"line" | "bar"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const optionsDetail: ChartOptions<"line" | "bar"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      y: {
        beginAtZero: true, // Start Y-axis at 0
        ticks: {
          stepSize: 5, // Increment by 5
          callback: (value) => `${value}`, // Optional: Add units like 'kWh'
        },
      },
      x: {
        ticks: {
          autoSkip: false, // Show all labels on X-axis
        },
      },
    },
  };

  const handleOpenModalLine = () => setShowModalLine(true); // Open modal
  const handleCloseModalLine = () => setShowModalLine(false); // Close modal

  const handleOpenModalBar = () => setShowModalBar(true); // Open modal
  const handleCloseModalBar = () => setShowModalBar(false); // Close modal

  return (
    <div className="energy-dashboard">
      {/* Top Metrics */}
      <div className="energy-top-metrics">
        <div className="energy-metric">
          <h3>48,03 €</h3>
          <p>Receita Hoje</p>
        </div>
        <div className="energy-metric">
          <h3>414,09 kWh</h3>
          <p>Rendimento Hoje</p>
        </div>
        <div className="energy-metric">
          <h3>233,09 kWh</h3>
          <p>Fornecimento da Rede Elétrica Hoje</p>
        </div>
        <div className="energy-metric">
          <h3>76,09 MWh</h3>
          <p>Rendimento Total</p>
        </div>
      </div>

      {/* Alerts */}
      <div className="energy-alerts">
        <div className="energy-alert">
          <span className="energy-alert-badge energy-serious"></span> Sério: 0
        </div>
        <div className="energy-alert">
          <span className="energy-alert-badge energy-important"></span> Importante: 0
        </div>
        <div className="energy-alert">
          <span className="energy-alert-badge energy-secondary"></span> Secundário: 0
        </div>
        <div className="energy-alert">
          <span className="energy-alert-badge energy-advert"></span> Advert.: 0
        </div>
      </div>

      {/* Graphs and Charts */}
      <div className="energy-charts">
        {/* Line Chart */}
        <div className="energy-line-chart">
          <div className="chart-header">
            <h4>{lineChartTitle}</h4>
            <div className="chart-controls">
              <input
                type="date"
                value={lineChartDate}
                onChange={(e) => setLineChartDate(e.target.value)}
                className="energy-date-picker"
              />
              <div className="view-selector">
                {["Day", "Month", "Year"].map((view) => (
                  <button
                    key={view}
                    className={`view-btn ${lineChartView === view ? "active" : ""}`}
                    onClick={() => {
                      updateLineChartView(view as "Day" | "Month" | "Year");
                      updateLineDetailChartView(view as "Day" | "Month" | "Year");
                    }}
                  >
                    {view === "Day" ? "Dia" : view === "Month" ? "Mês" : "Ano"}
                  </button>
                ))}
              </div>
            </div>
            <button className="energy-expand-btn" onClick={handleOpenModalLine}>
              <FaExpandArrowsAlt />
            </button>
          </div>
          <Line data={lineData} options={options} />
        </div>


        {/* Bar Chart */}
        <div className="energy-bar-chart">
          <div className="chart-header">
            <h4>{barChartTitle}</h4>
            <div className="chart-controls">
              <input
                type="date"
                value={barChartDate}
                onChange={(e) => setBarChartDate(e.target.value)}
                className="energy-date-picker"
              />
              <div className="view-selector">
                {["Day", "Month", "Year"].map((view) => (
                  <button
                    key={view}
                    className={`view-btn ${barChartView === view ? "active" : ""}`}
                    onClick={() => {
                      updateBarChartView(view as "Day" | "Month" | "Year");
                      updateBarDetailChartView(view as "Day" | "Month" | "Year");
                    }}
                  >
                    {view === "Day" ? "Dia" : view === "Month" ? "Mês" : "Ano"}
                  </button>
                ))}
              </div>
            </div>
            <button className="energy-expand-btn" onClick={handleOpenModalBar}>
              <FaExpandArrowsAlt />
            </button>
          </div>
          <Bar data={barData} options={options} />
        </div>
      </div>

      <Modal show={showModalLine} onHide={handleCloseModalLine} className="energy-detail-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title className="energy-modal-tittle">{lineChartTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="energy-detail-modal-graph">
            <Line data={lineDataDetail} options={optionsDetail} />
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalBar} onHide={handleCloseModalBar} className="energy-detail-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title className="energy-modal-tittle">{barChartTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="energy-detail-modal-graph">
            <Bar data={barDataDetail} options={optionsDetail} />
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

    </div >
  );
};

export default EnergyDashboard;

import * as React from "react"
import { CardSvgProps } from '@lagoagest-lda/common-frontend-core';

const SvgComponent: React.FC<CardSvgProps> = ({ width = 32, height = 32, color1 = '#47ab42' }) => (
    <svg width={width} height={height} viewBox="0 0 329.983 329.983" xmlns="http://www.w3.org/2000/svg">
        <g fill={color1}>
            <path d="M175.426 1.282a7.498 7.498 0 0 0-6.183 3.255c-4.089 5.955-100.145 146.668-100.145 217.837 0 58.629 47.698 106.327 106.327 106.327s106.326-47.698 106.326-106.327c0-71.169-96.055-211.882-100.144-217.837a7.496 7.496 0 0 0-6.181-3.255zm0 312.419c-50.358 0-91.327-40.969-91.327-91.327 0-56.606 69.872-167.393 91.327-200.073 21.455 32.68 91.326 143.466 91.326 200.073 0 50.358-40.969 91.327-91.326 91.327z" />
            <path d="M167.927 286.199a7.5 7.5 0 0 0 7.5 7.5c39.33 0 71.328-31.996 71.328-71.323 0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5c0 31.057-25.269 56.323-56.328 56.323a7.5 7.5 0 0 0-7.5 7.5zM322.483 31.658h-14.574V17.083c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v14.575h-14.575c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h14.575v14.575c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5V46.658h14.574c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5zM51.65 257.492H37.075v-14.575c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v14.575H7.5c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h14.575v14.575c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-14.575H51.65c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z" />
        </g>
    </svg>
)

export default SvgComponent

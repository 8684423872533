import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MdOutlineEditNote, MdOutlineInventory, MdOutlineInventory2, MdOutlinePriceChange, MdOutlinePublishedWithChanges } from 'react-icons/md';
import { navbarData, sidebarData } from '../../../data';
import { Page } from '@lagoagest-lda/common-frontend-core';
import { StockMovementTable, StockCurrentCustomer, RentingStockMovement } from '@lagoagest-lda/module-renting-frontend';

const RentingStockMovementPage = () => {
    const { t } = useTranslation();
    const pageContent = (<RentingStockMovement />);

    const sidebarItems = [
        {
            icon: <MdOutlinePriceChange />,
            label: t('module.renting.customerPrice.header'),
            section: 'customer/price'
        },
        {
            child: [
                {
                    icon: <MdOutlineInventory />,
                    label: t('module.renting.stockCurrent.header'),
                    section: 'stock/current'
                },
                {
                    icon: <MdOutlinePublishedWithChanges />,
                    label: t('module.renting.stockMovement.header'),
                    section: 'stock/movement'
                },
                {
                    icon: <MdOutlineEditNote />,
                    label: t('module.renting.stockManagement.header'),
                    section: 'stock/management'
                }
            ],
            icon: <MdOutlineInventory2 />,
            label: 'Stock'
        }
    ];

    const sidebar = {
        //collapsable: false,
        hasSettings: true,
        hasProfile: true,
        getCurrentUser: navbarData.getCurrentUser,
        items: sidebarItems,
        logout: navbarData.logout
        //title: 'tbd'
    }

    return <Page content={pageContent} logout={navbarData.logout} navbar={navbarData} sidebar={sidebar} />;
};

export default RentingStockMovementPage;
import React from "react"
import { CardSvgProps } from '@lagoagest-lda/common-frontend-core';

const SvgComponent: React.FC<CardSvgProps> = ({ width = 32, height = 32, color1 = '#47ab42' }) => (
    <svg width={width} height={height} viewBox="0 0 512 512" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
        <path d="M339.478 0H139.13C93.1 0 55.652 37.448 55.652 83.478v278.261c0 22.439 8.916 42.823 23.371 57.839-14.03 8.885-23.371 24.535-23.371 42.335 0 27.618 22.469 50.087 50.087 50.087h233.739c64.442 0 116.87-52.428 116.87-116.87V116.87C456.348 52.428 403.92 0 339.478 0zm0 478.609H105.739c-9.206 0-16.696-7.49-16.696-16.696s7.49-16.696 16.696-16.696h233.739c27.618 0 50.087-22.469 50.087-50.087s-22.469-50.087-50.087-50.087H139.13c-9.22 0-16.696 7.475-16.696 16.696s7.475 16.696 16.696 16.696h200.348c9.206 0 16.696 7.49 16.696 16.696s-7.49 16.696-16.696 16.696H139.13c-27.618 0-50.087-22.469-50.087-50.087s22.469-50.087 50.087-50.087h200.348c46.03 0 83.478 37.448 83.478 83.478s-37.448 83.478-83.478 83.478zm83.479-165.18c-21.229-21.685-50.806-35.168-83.478-35.168H139.13c-18.778 0-36.125 6.236-50.087 16.741V83.478c0-27.618 22.469-50.087 50.087-50.087h200.348c46.03 0 83.478 37.448 83.478 83.478v196.56z" 
            fill={color1}/>
    </svg>
)

export default SvgComponent

import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import Inov2030 from './pages/cofunding/inov2030';
import LoginPage from './pages/login';
import ProfilePage from './pages/profile';
import SettingsPage from './pages/settings';
import RentingCustomerPricePage from './pages/renting/customer/price';
import RentingStockCurrentPage from './pages/renting/stock/current';
import RentingStockManagementPage from './pages/renting/stock/management';
import RentingStockMovementPage from './pages/renting/stock/movement';
import RentingStockAccumulatedPage from './pages/renting/stock/accumulated.js';
import { PageNotFound } from '@lagoagest-lda/common-frontend-core'
import FloorPlannerPage from './pages/floorplanner/floorplanner';
import MetricsPage from './pages/metrics/metrics';
import EnergyPageBeta from './pages/energyBeta/index.tsx';
import EnergyPage from './pages/energy/energy';
import DistributionPage from './pages/distribution/distribution';


import { navbarData, footerData } from './data';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';

function App() {
    const [expirationTime, setExpirationTime] = useState(null);
    let currentStore = false;
    if (localStorage.getItem("user") !== null) currentStore = true;

    useEffect(() => {
        const user = navbarData.getCurrentUser();
        if (!user) return;
        const { exp } = JSON.parse(atob(user.token.split(".")[1]));
        setExpirationTime(exp * 1000); // Convert exp to milliseconds
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            // Verify if the token is expired
            if (!expirationTime || expirationTime >= Date.now()) return;
            // Clear the local storage
            localStorage.clear();
            // Navigate to the homepage
            window.location.href = '/';
        }, 1);
        return () => clearInterval(timer);
    }, [expirationTime]);

    return (
        <Router>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/inov-2030' element={<Inov2030 />} />
                {!currentStore && <Route path='/login' element={<LoginPage />} />}
                {currentStore && <Route path="/profile" element={<ProfilePage />} />}
                {currentStore && <Route path="/settings" element={<SettingsPage />} />}
                {currentStore && <Route path="/plant" element={<FloorPlannerPage />} />}
                {currentStore && <Route path="/metrics" element={<MetricsPage />} />}
                {currentStore && <Route path="/customer/price" element={<RentingCustomerPricePage />} />}
                {currentStore && <Route path="/stock/current" element={<RentingStockCurrentPage />} />}
                {currentStore && <Route path="/stock/management" element={<RentingStockManagementPage />} />}
                {currentStore && <Route path="/stock/movement" element={<RentingStockMovementPage />} />}
                {currentStore && <Route path="/stock/accumulated" element={<RentingStockAccumulatedPage />} />}
                {currentStore && <Route path="/energyBeta" element={<EnergyPageBeta />} />}
                {currentStore && <Route path="/energy" element={<EnergyPage />} />}
                {currentStore && <Route path="/distribution" element={<DistributionPage />} />}
                <Route path="/*" element={<PageNotFound navbar={navbarData} footer={footerData} />} />
            </Routes>
        </Router>
    );
}

export default App;
